






























































    import {Component, Vue, Watch, Prop, Emit} from 'vue-property-decorator';
    import DataTable from '@/components/common/Tables/DataTable.vue';
    import Snackbar from "@/components/common/Snackbar.vue";
    import Navbar from '@/components/common/Navbar.vue';
    import MTStockEditRequestService from "@/services/MTStockEditRequestService";
    import {IOpeningStockEditItems, IOpeningStockRequestManager, IRequestActionParams} from '@/interfaces/RequestManager';
    import { ITableHeader} from '@/interfaces/common';
    import moment from 'moment'
    @Component({
        components:{
            DataTable,
            Snackbar,
            Navbar
        }       
    })
export default class InwardEditRequestEditItem extends Vue {
    @Prop() readonly ItemDetails!:any;
    @Prop() toView!:boolean;
    @Prop() requestDetails!:IRequestActionParams;
    @Prop() toArchived!:boolean;
    @Prop() readonly requestInfo!:string;
    @Prop() readonly InvoiceNumber!:string;
    private snackBarMessage: string = "";
    private snackbar: boolean = false;
    private snackColor: string = "red";
    // private editItems:Array<IOpeningStockEditItems>=[];
    private tableHeaders:Array<ITableHeader>=[
        {
            text:'SKU Name', 
            value:'productName',
            sortable:true,
            default:'-'
        },
        {
            text:'Inward (Old)',
            value:'InwardOld',
            sortable:false,
            default:'-'
        },
        {
            text:'Inward (New)',
            value:'InwardNew',
            sortable:false,
            default:'-'
        },
    ]
    @Emit('navigate')
    navigate(){
        return;
    }
    private loading1:boolean=false;
    private loading2:boolean=false;
    @Emit('approveRequest')
    approveRequest(item:IRequestActionParams)
    {
        this.loading1=true;
        return
        // var Id=item.Id;
        // var status=1;
        // MTStockEditRequestService.ReviewRequest(Id,status).then((res)=>{
        //   this.n=1;  
        // })
        // .catch((err)=>{
        //     console.log(err);
        // })
        
    }
    @Emit('rejectRequest')
    rejectRequest(item:IRequestActionParams)
    {
        this.loading2=true;
        return;
        
    }
}
