

































































































    import {Component, Vue, Watch, Prop, Emit} from 'vue-property-decorator';
    import { ITableHeader} from '@/interfaces/common';
    import Navbar from '@/components/common/Navbar.vue';
    import {IOpeningStockRequestManager, IOpeningStockEditItems,IRequestActionParams, ITertiaryStockEditRequestItem, IInwardStockEditRequestItem} from '@/interfaces/RequestManager';
    import DataTable from '@/components/common/Tables/DataTable.vue';
    import Snackbar from "@/components/common/Snackbar.vue";
    import MTStockEditRequestService from "@/services/MTStockEditRequestService";
    import SearchBar from "@/components/common/SearchBar.vue";
    import DropDown from "@/components/common/Inputs/DropDown.vue";
    import RequestEditItem from "@/components/ModernTrade/RequestHandler/OpenStockRequestHandler/RequestEditItem.vue"
    import {RequestType} from '@/Enum/MTStockEditRequest'
    import TertiaryEditRequestEditItem from '@/components/ModernTrade/RequestHandler/OpenStockRequestHandler/TertiaryEditRequestItem.vue'
    import InwardEditRequestEditItem from '@/components/ModernTrade/RequestHandler/OpenStockRequestHandler/InwardEditRequestItem.vue'
    import moment from 'moment'
    Vue.prototype.moment = moment
    @Component({
        components:{
            DataTable,
            Snackbar,
            Navbar,
            RequestEditItem,
            SearchBar,
            DropDown,
            TertiaryEditRequestEditItem,
            InwardEditRequestEditItem
        }
    })
export default class RequestList extends Vue{
    private snackBarMessage: string = "";
    private snackbar: boolean = false;
    private snackColor: string = "red";
    private toViewSKU:boolean=false;
    private hideButton:string=""
    private search:string=""
    private editrequestItem:any={};
    private editdetails:IRequestActionParams={
        Id:0,
        requestStatus:0,
        requesttype:0,
    };
    private toarhchivedList:boolean=false
    private editItems:Array<IOpeningStockEditItems>=[];
    private editInwarditem:Array<IInwardStockEditRequestItem>=[];
    private editTertiaryitem:Array<ITertiaryStockEditRequestItem>=[];
    
    private requestTypes:Array<any>=[
        {key:1, value:"Opening Stock Edit"},
        {key:2, value:"Inward Edit"},
        {key:3, value:"Tertiary Take-Off Edit"}
    ]

    private RequestStatus: Array<any>=[
        {key:0, value:"Pending"},
        {key:1, value:"Approved"},
        {key:2, value:"Rejected"}  
    ]
    private StockEditRequestType:Array<any>=[
        
        {
            id:RequestType.OpeningStock,
            name:"Opening Stock Edit"
        },
        {
            id:RequestType.Inward,
            name:"Inward Edit"
        },
        {
            id:RequestType.tertiary,
            name:"Tertiary Take-Off Edit"
        }
    ]

    private tableHeadersPending:Array<ITableHeader>=[
        {
            text:'Employee Name',
            value:'employee',
            sortable:false,
            default:'-'
        },
        {
            text:'Outlet Name',
            value:'outlet',
            sortable:false,
            default:'-'
        },
        {
            text:'Request Date',
            value:'requestDate',
            sortable:true,
            default:'-'
        },
        {
            text:'Request Type',
            value:'reqeustType',
            sortable:false,
            default:'-'
        },
        {
            text:'Reason',
            value:'reason',
            sortable:false,
            default:'-'
        },
        {
            text:'Action',
            value:'action',
            sortable:false,
            default:''
        }
    ]
    private tableHeadersArchived:Array<ITableHeader>=[
        {
            text:'Employee Name',
            value:'employee',
            sortable:false,
            default:'-'
        },
        {
            text:'Outlet Name',
            value:'outlet',
            sortable:false,
            default:'-'
        },
        {
            text:'Reason',
            value:'reason',
            sortable:false,
            default:'-'
        },
        {
            text:'Request Type',
            value:'reqeustType',
            sortable:false,
            default:'-'
        },
        {
            text:'Request Date',
            value:'requestDate',
            sortable:true,
            default:'-'
        },
        {
            text:'Action Date',
            value:'actionDate',
            sortable:true,
            default:'-'   
        },
        {
            text:'Action Taken',
            value:'actionTaken',
            sortable:false,
            default:''
        },
        {
            text:'Details',
            value:'action',
            sortable:false,
            default:''
        }


    ]
    private ActiveList:Array<IOpeningStockRequestManager>=[]
    public async refreshList(){
        this.toarhchivedList? await this.getArchivedList(!this.toarhchivedList): await this.getPendingRequestList(!this.toarhchivedList);
    }
    public type:Array<any>=[];
    public GetFilteredList(types:Array<any>)
    {
        this.type=[];
        this.ActiveList=[];
        types.forEach((element:any,index:number)=>{
            this.getPendingTertiaryEditRequestList(!this.toarhchivedList,element.id);
            this.type.push(element.id)
        })
        if(this.type.length == 0){
            this.getPendingTertiaryEditRequestList(!this.toarhchivedList,0);
        }
    }
    navigateBack()
    {
        this.toViewSKU=false;
    }
    public  approveRequest(data:IRequestActionParams)
    { 
        var Id=data.Id;
        var status=1;
        MTStockEditRequestService.ReviewRequest(Id,status,data.requesttype).then((res)=>{
         
         
        this.toViewSKU=false;
        this.snackbar=true;
        this.snackColor="green";
        this.snackBarMessage="Request Aprroved.";
        this.refreshList();
          
        })
        .catch((err)=>{
            console.log(err);
        })

    }
    public rejectRequest(data:IRequestActionParams):void
    { 
        var Id=data.Id;
        var status=2;
        MTStockEditRequestService.ReviewRequest(Id,status, data.requesttype).then((res)=>{
          this.refreshList();
          this.toViewSKU=false;
          this.snackbar=true;
          this.snackColor="yellow";
          this.snackBarMessage="Request Rejected.";
          
        })
        .catch((err)=>{
            console.log(err);
        })

    }
    private data:any={}
    private requestDate:string="";
    public invoiceNumber:string="";
    public editRequesttype:RequestType=0;
    toView(request:IOpeningStockRequestManager)
    {
        this.toViewSKU=true;
        this.editdetails.Id=request.Id;
        this.editdetails.requesttype=request.reqeustType;
        this.editdetails.requestStatus=request.requestStatus;
        this.requestDate=request.stockDate;
        this.editRequesttype=request.reqeustType;
        this.invoiceNumber=request.invoiceNumber;

        let that=this;
        if(request.reqeustType==RequestType.OpeningStock){
        MTStockEditRequestService.GetEditRequestItems(request.Id,request.reqeustType)
        .then((res:any)=>{
            that.editrequestItem=res.data;
            this.editItems=[];
            res.data.forEach((element:any, index:number)=>{
            var item:IOpeningStockEditItems={
                Id:0,
                productId:0,
                productName:"",
                stockValueOld:0,
                stockValueNew:0,
                closeToExpiryOld:0,
                closeToExpiryNew:0,
                returnOld:0,
                returnNew:0,
                priceOld:0,
                priceNew:0,
                returnReasonOld:"",
                returnReasonNew:"",
                batchNumberOld:"",
                batchNumberNew:""

            };
            item.Id=element.id,
            item.productId=element.productId,
            item.productName=element.productName,
            item.stockValueOld=element.stockValueOld,
            item.stockValueNew=element.stockValueNew,
            item.closeToExpiryOld=element.closeToExpiryOld,
            item.closeToExpiryNew=element.closeToExpiryNew,
            item.returnOld=element.returnOld,
            item.returnNew=element.returnNew,
            item.priceOld=element.priceOld,
            item.priceNew=element.priceNew,
            item.returnReasonOld=element.returnReasonOld,
            item.returnReasonNew=element.returnReasonNew,
            item.batchNumberOld=element.batchNumberOld,
            item.batchNumberNew=element.batchNumberNew,

            this.editItems.push(item);
        })
        })
        .catch((err:any)=>{
            console.log(err);
        })
        }
        else if(request.reqeustType==RequestType.tertiary)
        {
            MTStockEditRequestService.GetEditRequestItems(request.Id,request.reqeustType)
            .then((res:any)=>{
                this.editrequestItem=res.data;
                this.editTertiaryitem=[];
                res.data.forEach((element:any,index:number)=>{
                    var item:ITertiaryStockEditRequestItem={
                        Id:0,
                        productId:0,
                        productName:"",
                        TertiaryOld:0,
                        TertiaryNew:0,
                    }
                    item.Id=element.id,
                    item.productId=element.productId,
                    item.productName=element.productName,
                    item.TertiaryOld=element.saleValueOld,
                    item.TertiaryNew=element.saleValueNew,

                    this.editTertiaryitem.push(item);
                })
            })
            .catch((err:any)=>{
                console.log(err);
            })

        }

        else if(request.reqeustType==RequestType.Inward)
        {
            MTStockEditRequestService.GetEditRequestItems(request.Id,request.reqeustType)
            .then((res:any)=>{
                this.editrequestItem=res.data;
                this.editInwarditem=[];
                res.data.forEach((element:any,index:number)=>{
                    var item:IInwardStockEditRequestItem={
                        Id:0,
                        productId:0,
                        productName:"",
                        InwardOld:0,
                        InwardNew:0,
                    }
                    item.Id=element.id,
                    item.productId=element.productId,
                    item.productName=element.productName,
                    item.InwardOld=element.saleValueOld,
                    item.InwardNew=element.saleValueNew,

                    this.editInwarditem.push(item);
                })
            })
            .catch((err:any)=>{
                console.log(err);
            })

        }

    }
    switchList(status:boolean)
    {
        if(status==false)
        {
            this.getPendingRequestList(!status);
        }
        else
        {
            this.getArchivedList(!status);
        }
    }
    public async getPendingRequestList(pending:boolean):Promise<boolean>
    {
        let that=this;
        return new Promise<boolean>(response=>{
        MTStockEditRequestService.GetEditRequests(pending,RequestType.All)
        .then((res:any)=>{
            
            that.ActiveList=[];
            res.data.forEach((element:any, index:number)=>{
                var item:IOpeningStockRequestManager={
                    Id:0,
                    employee:"",
                    employeeId:0,
                    outlet:"",
                    outletId:0,
                    actionDate:"",
                    stockDate:"",
                    year:"",
                    reqeustType:0,
                    month:"",
                    requestDate:"",
                    reason:"",
                    invoiceNumber:"",
                    requestStatus:0
                };
                item.Id=element.id,
                item.employee=element.employeeName,
                item.employeeId=element.employeeId,
                item.outlet=element.outletName,
                item.outletId=element.outletId,
                item.stockDate=element.lastStockTakenDate,
                item.year=element.year,
                item.reqeustType=element.requestType,
                item.month=element.month,
                item.invoiceNumber=element.invoiceNumber,
                item.requestDate=element.deviceTime,
                item.requestStatus=element.requestStatus,
                item.reason=element.stockEditReason,

                that.ActiveList.push(item);
                response(true);
            })
        })
        .catch((err:any)=>{
            console.log(err);
        });
        
        })
    }
    public async getPendingTertiaryEditRequestList(pending:boolean, type:RequestType):Promise<boolean>
    {
        let that=this;
        return new Promise<boolean>(response=>{
        MTStockEditRequestService.GetEditRequests(pending,type)
        .then((res:any)=>{
            res.data.forEach((element:any, index:number)=>{
                var item:IOpeningStockRequestManager={
                    Id:0,
                    employee:"",
                    employeeId:0,
                    outlet:"",
                    outletId:0,
                    actionDate:"",
                    stockDate:"",
                    year:"",
                    reqeustType:0,
                    month:"",
                    requestDate:"",
                    reason:"",
                    invoiceNumber:"",
                    requestStatus:0
                };
                item.Id=element.id;
                item.employee=element.employeeName;
                item.employeeId=element.employeeId;
                item.outlet=element.outletName;
                item.outletId=element.outletId;
                item.stockDate=element.lastStockTakenDate,
                item.year=element.year,
                item.reqeustType=element.requestType,
                item.month=element.month,
                item.invoiceNumber=element.invoiceNumber,
                item.requestDate=element.deviceTime,
                item.requestStatus=element.requestStatus,
                item.reason=element.stockEditReason;
                if(pending==false)
                {
                    item.actionDate=element.actionDateTime
                };

                that.ActiveList.push(item);
            })
        })
        .catch((err:any)=>{
            console.log(err);
        });
        })
    }
    public async getArchivedList(pending:boolean):Promise<boolean>
    {
        let that=this;
        return new Promise<boolean>(resposne=>{
        MTStockEditRequestService.GetEditRequests(pending,RequestType.All)
        .then((res:any)=>{
            that.ActiveList=[];
            this.data=res.data
            res.data.forEach((element:any, index:number)=>{
                var item:IOpeningStockRequestManager={
                    Id:0,
                    employee:"",
                    employeeId:0,
                    outlet:"",
                    outletId:0,
                    actionDate:"",
                    reqeustType:0,
                    stockDate:"",
                    year:"",
                    month:"",
                    invoiceNumber:"",
                    requestDate:"",
                    reason:"",
                    requestStatus:0
                };
                item.Id=element.id,
                item.employee=element.employeeName,
                item.employeeId=element.employeeId,
                item.outlet=element.outletName,
                item.outletId=element.outletId,
                item.stockDate=element.lastStockTakenDate,
                item.year=element.year,
                item.reqeustType=element.requestType,
                item.month=element.month,
                item.invoiceNumber=element.invoiceNumber,
                item.requestDate=element.deviceTime,
                item.requestStatus=element.requestStatus,
                item.reason=element.stockEditReason,
                item.actionDate=element.actionDateTime,

                that.ActiveList.push(item);
                resposne(true);
            })
            //that.getPendingTertiaryEditRequestList(pending,RequestType.All);
            //that.getPendingTertiaryEditRequestList(pending,RequestType.Inward);
        })
        .catch((err:any)=>{
            console.log(err);
        });
        })

    }

  closeSnackbar(value: boolean) {
    this.snackbar = value;
  }
  mounted()
  {
      this.getPendingRequestList(true);
  }

}
