import client from './../apiService';
import {ISRStockCaptureEditRequest} from '../Enum/ISRStockCaptureEditRequestStatus'
import {RequestType}  from '../Enum/MTStockEditRequest'

class MTStockEditRequestService{
    GetEditRequests(pending:boolean, type:RequestType){
        const url=`MTStockEditRequest/GetEditRequests?pending=${pending}&type=${type}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    GetEditRequestItems(recordId:number,type:RequestType){
        const url=`MTStockEditRequest/GetEditRequestItems?recordId=${recordId}&type=${type}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

    }
    ReviewRequest(recordId:number,status:ISRStockCaptureEditRequest,type:RequestType)
    {
        const url = `MTStockEditRequest/ReviewRequest?recordId=${recordId}&iSRStockCaptureEditRequestStatus=${status}&type=${type}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    
    }
}
export default new MTStockEditRequestService();